<template>
    <b-card style="max-width: 100%; border-left: 4px solid #5cb85c;" class="mb-1">
        <b-card-text>
            <b-row>
                <b-col>
                    <strong> {{  event.grupo.name  }} </strong>  
                    <div class="float-right">
                        <b-button size="sm" variant="info" class="mr-1 mb-1 button-small"
                            @click.stop="editEvent(event)"                                        
                        >
                            <b-icon icon="pencil-fill" ></b-icon>
                        </b-button>                                    
                        <b-button size="sm" variant="danger" class="mr-1 mb-1 button-small" 
                            @click.stop="deleteEvent(event)"
                        >
                            <b-icon icon="x"></b-icon>                                   
                        </b-button>                       
                    </div>                  
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong> {{  event.nombreEvento.toUpperCase()  }} </strong>  
                </b-col>                
            </b-row>
            <b-row>
                <b-col>
                    <strong>Fecha y hora inicio</strong><br>
                    {{event.startDate}}-{{event.startTime}}
                </b-col>
                <b-col>
                    <strong>Fecha y hora fin</strong><br>                     
                    {{event.endDate}}-{{event.endTime}}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong style="font-size:.80em">Montaje: {{ event.montaje ? event.montaje.name : '' }}</strong> <br>
                    <strong style="font-size:.80em">Locación: {{  nameLocation( event.locacion  ) }}</strong>                    
                </b-col>
                <b-col>
                    <strong style="font-size:.75em">AD-NI-INF</strong><br>
                    <span class="badge badge-secondary">{{event.adult}}</span>-<span class="badge badge-secondary">{{event.child}}</span>-<span class="badge badge-secondary">{{event.infant}}</span> 
                </b-col>
            </b-row>   
            <hr style="width:100%;text-align:left;margin-bottom:0.2em; border: 1px solid #82b1ff">  
            <b-row >
                <b-col>                    
                    <strong style="font-size:.75em" v-if="event.backup">Backup: {{ event.backup ? nameLocation(event.backup) : '' }}</strong>
                    <span v-else class="badge badge-warning" style="font-size:.75em">Sin backup establecido</span>
                </b-col> 
                <b-col >                    
                    <strong style="font-size:.75em">Creado por: {{ event.createdby ? event.createdby.first_name+' '+event.createdby.last_name : '' }}</strong> <br>
                    <strong style="font-size:.75em">Fecha creación: {{ event.createdate }} </strong><br>
                      <strong style="font-size:.75em">Actualizado por: {{ event.updatedby ? event.updatedby.first_name+' '+event.updatedby.last_name : '' }}</strong><br>
                      <strong style="font-size:.75em">Fecha actualización: {{ event.updatedate}}</strong>
                </b-col>                
            </b-row>                              
        </b-card-text>
    </b-card>
</template>
<script>
import { mapState} from 'vuex'

export default {
    name: 'Event',
    props:{
        event: {
            type: Object,
            required: true
        }        
    },
    computed: {
        ...mapState('init', ['locations','events']),
        ...mapState('calendar', ['groups']),                
    },
    methods: {
        nameLocation( idLocation ){
            const location = this.locations.find( location => location.id === idLocation )            
            return location ? location.name : ''
        },
                
        editEvent(){        
            this.$emit('edit-event', this.event )
        },
        deleteEvent(){        
            this.$emit('delete-event', this.event )
        }      
    }

}
</script>
